import React, { useEffect, useRef } from 'react';
import SecurityServiceItem from './Service/SecuirtyServiceItem';
import Sectitle from '../components/Title/Sectitle';
import Pserviceslider from '../components/Service/Sservice/Pserviceslider';



const Unique = () => {

    const videoref = useRef(null);

    useEffect(() => {
        const video = videoref.current;
    
        const handleEnded = () => {
          // Restart video playback when it ends
          video.currentTime = 0; // Reset video to the beginning
          video.play();
        };
    
        // Add event listener for 'ended' event
        video.addEventListener('ended', handleEnded);
    
        // Clean up event listener when component unmounts
        return () => {
          video.removeEventListener('ended', handleEnded);
        };
    }, []);

    return(
            <div>

                <section className="h_security_about_area">
                    <div className="container">
                        <SecurityServiceItem image="about_img_1.jpg" Title="Why Choose Renew Metal?"
                        description="Renew Metal is your trusted partner in responsible copper and brass recycling. With cutting-edge processes and a dedicated team, we ensure a positive impact on the environment, turning metal waste into renewed resources. Choose Renew Metals for a greener, more sustainable future."/>
                        
                    </div>
                </section>
                <section className="h_security_about_area">
                    <div className="container">
                        <idv className= "row">
                            <div className='col-lg-6 mx-auto'>  
                                <video ref={videoref}  width="100%" height="100%" controls muted autoPlay loop>
                                    <source src={require("../img/RenewMetals/process.mp4")}type='video/mp4' />
                                </video>
                            </div>

                        </idv>
                        
                    </div>
                </section>
                <section className="prototype_service_area_two">
                    <div className="container custom_container">
                        <Sectitle Title="Why come to us?" TitleP="Come to us to experience the professionalism, reliability, and top-notch service that sets us apart. Contact us today to discuss your scrap metal recycling requirements or to learn more about our services."
                        tClass="t_color3" sClass="sec_title text-center mb_70"/>
                        <Pserviceslider/>
                    </div>
                </section>
            </div>
    )
}

export default Unique;
