const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            image : 'PVC.jpeg',
            title : 'PVC Recycling',
            description: 'with the introduction of upgraded machines and techniques, revolutionizing the process of handling this ubiquitous material. Modern recycling facilities employ cutting-edge technology to efficiently sort, shred, and process PVC waste, ensuring maximum recovery of valuable resources while minimizing environmental impact.',
            
        },
        {
            id : 2,
            image : 'Copper_Collection.jpeg',
            title : 'Copper Recycling',
            description: 'We specialise in the recycling of copper materials, ranging from wires and pipes to copper-based products. Our efficient and eco-friendly processes ensure maximum extraction and reutilisation of copper resources.',
        },
        {
            id : 3 ,
            image : 'Brass_Recycling.jpeg',
            title : 'Brass Recycling',
            description: 'Brass items, such as valves, fittings, and decorative pieces, are a significant part of our recycling expertise. We carefully process and recycle brass materials to lessen environmental impact and provide a sustainable solution for brass waste.',
        },
        {
            id : 4,
            image : 'Metal_Collection.jpeg',
            title : 'Metal Collection',
            description: 'We offer convenient metal collection services to individuals, businesses, and industries. Our team can handle the pickup and transportation of your scrap copper and brass, making it hassle-free for you to contribute to the recycling process.',
        },
        {
            id : 5,
            image : 'Aluminium_Recycling.jpg',
            title : 'Aluminium Recycling',
            description: 'Gather up all your leftover materials, with a focus on aluminum scraps, and exchange them for cash at RenewMetals.',
        },
        {
            id : 6,
            image : 'Stainless_steel.jpg',
            title : 'Stainless steel Recycling',
            description: 'RenewMetal stands out as the premier purchaser of stainless steel scrap across Australia. Reach out to us for your scrap sales and receive cash in return.',
        },
        {
            id : 7,
            image : 'Lead.jpg',
            title : 'Recycling of lead scrap',
            description: 'If you have lead scrap taking up space at your workplace, RenewMetal Recycler is ready to purchase all of it and provide immediate cash payment.',
        },
        {
            id : 8,
            image : 'motors.jpg',
            title : 'Scrap Electric Motors',
            description: 'If you have unused electric motors lying around your workplace and you are unsure how to dispose of them, simply reach out to us for recycling services and receive payment in return.',
        },
    ]
    
}
export default ServiceData;