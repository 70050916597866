import React, {Component} from 'react';
import Testimonial from '../components/Testimonial/Testimonial';
import Fade from 'react-reveal/Fade'

class TestimonialMain extends Component {
    render(){
        let ClientData = this.props.ClientReview;
        return(
            <React.Fragment>
                
                <Testimonial tClass="testimonial_area sec_pad" Data={ClientData}/>
                
            </React.Fragment>
        )
    }
}

export default TestimonialMain;