import React, {Component} from 'react';
import Sectitle from '../Title/Sectitle';

const Services = (ServiceData) => {
    const services = ServiceData.ServiceData.Service;
    console.log(services);
    return(
            <section className="app_service_area" id="services">
                <div className="container">
                    <Sectitle />
                    <div className="row app_service_info">
                        {services.map((item) => {
                            return(
                                <div className="col-lg-4">
                                    <div>
                                        <img src={require("../../img/RenewMetals/"+ item.image)} alt="" />
                                    </div>
                                    <div className="app_service_item wow fadeInUp" data-wow-delay="0.2s">
                                        {/* <i className={`app_icon ${items.icon}`}></i> */}
                                        
                                        <h5 className="f_p f_size_18 f_600 t_color3 mt_40 mb-10">{item.title}</h5>
                                        <p className="f_400 f_size_15">{item.description}</p>
                                        
                                    </div>
                                </div>
                            )
                            
                        })}
                                    
                    </div>
                </div>
            </section>
    )
}

export default Services;