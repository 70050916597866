import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
class Sectitle  extends Component {
    render(){
        
        return(
            <div className="sec_title text-center mb_70" >
                <Reveal effect="fadeInUp" duration={1300}><h2 className="f_p f_size_30 l_height50 f_600 t_color3">Comprehensive solution For <br/> Your Every Need</h2></Reveal>
            </div>
        )
    }
}
export default Sectitle;
