import React, {Component} from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import SeoTitle from '../Title/SeoTitle';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        let data = this.props.Data;
        var {tClass}= this.props;
        return(
            <section className={`${tClass}`}>
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-6">
                            {/* <Fade bottom cascade> */}
                                <div className='tagline'>
                                    <div className='red-line'></div>
                                    <p> Testimonials</p>

                                </div>
                                <p className='testimonial_heading'>What Are People Saying About Us</p>
                                <div class="new-card">
                                    <div class="new-cardImg">
                                        <img src={require ("../../img/Mss/client.jpg")} alt="Image" />
                                    </div>
                                    <div class="new-cardDetails">
                                        <img src={require ("../../img/Mss/Star.png")} alt="Image" 
                                            width="120"
                                            height="20" />
                                        <h2 class="new-cardtitle">Timely Delivered</h2>
                                        <p class="new-carddescription">I am thoroughly impressed with the modern sea shipping services. From efficient handling to timely deliveries, their commitment to safety and reliability truly sets them apart. A trustworthy partner for seamless global shipping.</p>
                                        <h4>Hakeem</h4>
                                        <p class="new-carddescription">Client</p>
                                    </div>
                                </div>
                                

                                
                            {/* </Fade> */}
                        </div>
                        <div className='col-lg-6' >
                            {/* <div className="slider_nav">
                                <i className="ti-angle-left prev" onClick={this.previous}></i>
                                <i className="ti-angle-right next" onClick={this.next}></i>
                            </div> */}
                            {/* <div className="slider-card d-flex align-items-center"> */}
                                {/* <Slider ref={c => (this.slider = c)}  {...settings}>
                                    {
                                        data.ClientsReview.map(item =>{
                                            return(
                                                <div className="card-header" key={item.id}>
                                                    <h5>{item.title}</h5>
                                                    <div className="author_img">
                                                        <img src={require ("../../img/Mss/"+item.image +".png")}/>
                                                    </div>
                                                    <p>{item.description}</p>
                                                    <h5>{item.Name}</h5>
                                                    <h6>{item.authorPost}</h6>
                                                </div>
                                                    
                                                    <div class="slider-cardbody" key={item.id}>
                                                        <div class="slider-cardtitle">{item.title}</div>
                                                        <p class="cardslider-description">{item.description}</p>
                                                        <div class="icon">
                                                            <img src={require ("../../img/Mss/"+item.image +".png")} alt="Icon" />
                                                            <div class="slider-namedesignation">
                                                                <div class="slider-name">{item.Name}</div>
                                                                <div class="slider-designation">{item.authorPost}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                </Slider> */}
                                <React.Fragment>
                                    <Slider {...settings} className="slider-rightcard ">
                                        {data.ClientsReview.map(item => {
                                            return(

                                                

                                                <div className='slider-card'>
                                                    <div class="slider-cardbody" key={item.id}>
                                                        <div class="slider-cardtitle">{item.title}</div>
                                                        <p class="cardslider-description">{item.description}</p>
                                                        <img src={require ("../../img/Mss/Star.png")} alt="Image" 
                                                            width="120"
                                                            height="20" />
                                                        <div class="icon">
                                                            <img src={require ("../../img/Mss/"+item.image +".jpg")} alt="Icon" />
                                                            <div class="slider-namedesignation">
                                                                <div class="slider-name">{item.Name}</div>
                                                                <div class="slider-designation">{item.authorPost}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                
                                            );
                                            
                                        })}
                                        
                                        
                                    </Slider>
                                </React.Fragment>
                            {/* </div> */}


                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Testimonial;