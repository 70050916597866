import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class FooterThree extends Component{
    render(){
        
        // const loc = useLocation();
        


        let FooterData = this.props.FooterData;
        return(
            <footer className="footer_nine_area">
                <div className="footer_nine_top">
                    <div className="footer_shap left"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6">
                                <div className="f_widget company_widget pr_100">
                                    <Link to="/" className="f-logo">
                                        <img
                                            src={require("../../img/Mss/renew metal.png")}
                                                alt="logo"
                                                width="250"
                                                height="160"
                                        />
                                    </Link>
                                    <p className="f_400 f_p f_size_16 mb-0 l_height28 mt_40">Revive, Reclaim, Renew with Renew Metal</p>
                                    <div className="widget-wrap">
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-location-pin'> </i> <a href="#" className="f_400">RenewMetal Australia</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-email'> </i> <a href="mailto:info@renewmetal.com.au" className="f_400">info@renewmetal.com.au</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-mobile'></i> <a href="tel:0460786110" className="f_400">0460 786 110</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-mobile'></i> <a href="tel:0387745990" className="f_400">03 8774 5990</a></p>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-2 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">About Us</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.aboutLinks.map(links => {
                                                return(
                                                    <li key={links.id}><Link to={links.url}>{links.item}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">Help & Suport</h3>
                                    <ul className="list-unstyled f_list">
                                        
                                        <li ><Link to="/About" role="button" onclick = {() => {window.scrollTo(0, 0);}}>About</Link></li>
                                        <li > <Link to="/" role="button" onclick = {() => {window.scrollTo(0, 0);}}>Services</Link></li>
                                               
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">Privacy Contact</h3>
                                    <ul className="list-unstyled f_list">
                                        
                                        <li><Link to= "/Contact" role= "button" onclick={() => {window.scrollTo(0, 0);}}> Contact Us</Link></li>
                                                
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_nine_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7 align-self-center">
                                <p className="mb-0 f_400">© Renew Metal 2023. All Rights Reserved</p>
                            </div>
                            <div className="col-sm-5">
                                <p className="mb-0 f_400">Designed & Developed By <a href='https://eparameter.com/'><b>eParameter</b></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterThree;