import React from 'react';



const Benefits = () => {
    return(
        <section className="chat_core_features_area sec_pad">
            <div className="container">
                <div className="hosting_title chat_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                    Benefits Of <br/>Copper And Brass
                    </h2>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="chat_features_item wow fadeInUp">
                            <div className="round">
                            
                                <img src={require("../img/RenewMetals/Natural Resource.svg")} alt="" />
                            
                            
                                
                            </div>
                            
                            <h4>Conservation of Natural Resources</h4>
                            
                            <p>
                            Recycling copper and brass reduces the need for new mining and extraction, preserving valuable natural resources for future generations.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="round">
                            
                                <img src={require("../img/RenewMetals/Energy Savings.svg")} alt="" />
                            
                            </div>
                            
                            <h4>Energy Savings</h4>
                            
                            <p>
                            Recycling metals requires significantly less energy compared to mining and refining new metals, resulting in reduced carbon emissions and a smaller carbon footprint.
                            </p>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
                            <div className="round">
                            
                                <img src={require('../img/RenewMetals/Waste Reduction.svg')} alt="" />
                            
                            
                            
                            </div>
                            
                            <h4>Waste Reduction</h4>
                            
                            <p>
                            By recycling copper and brass, we help divert these materials from landfills, contributing to waste reduction and a cleaner environment.

                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="round">
                                
                                    <img src={require('../img/RenewMetals/Economic Impact.svg')} alt="" />
                                
                                
                                
                            </div>
                            
                            <h4>Economic Impact</h4>
                            
                            <p>
                            Recycling metals contributes to local economies, creating job opportunities in the recycling industry and supporting a more sustainable circular economy.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 
        
    )
}

export default Benefits;
