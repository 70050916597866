import React from 'react';
import Fade from 'react-reveal/Fade';

const AboutDetail =()=>{
    return(
        <React.Fragment>
            <section className="blog_area sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 ">
                            
                                <img src={require('../../img/RenewMetals/AboutDetail.jpeg')} alt=""
                                
                                    width="100%"
                                    height="60%"
                                
                                />
                            
                        </div>
                        <div className="col-lg-7 blog_sidebar_left">
                            
                                
                                <div className="blog_content">
                                    
                                    <p className="f_250 mb-20"> Our journey began in 2015 with just a single Ute, but through our dedication and commitment to providing exceptional service, we have grown to operate Victoria wide with a fleet of over 10 vehicles, including trucks. This expansion is a testament to the trust and loyalty we have fostered with our clients over the years. </p>
                                    <p className="f_250 mb_20">At our scrap metal business, we understand the value of transparency and honesty. That's why we always strive to offer top dollar for both ferrous and non-ferrous metals. Whether it's old appliances, industrial equipment, or automotive parts, we ensure that our clients receive the best possible return for their scrap metal. We take pride in our ability to accurately assess the market value of different metals and provide fair compensation accordingly.</p>
                                    
                                    <p className="f_250 mb-20">Our team of experienced professionals is equipped with the knowledge and skills to handle various scrap metal recycling projects. With a strong emphasis on environmental sustainability, we adhere to strict recycling processes and regulations. By choosing our services, you can contribute to the conservation of natural resources while also benefiting financially.</p>
                                    <p className="f_250 mb-20">As we continue to grow and expand our operations, we remain committed to delivering reliable and efficient scrap metal solutions for individuals and businesses alike. We look forward to serving you and building long-lasting relationships based on trust, honesty, and mutual success. Contact us today to learn more about our services and how we can help you recycle your scrap metal responsibly.</p>
                                    
                                </div>
                            
                        </div>
                        
                    </div>
                </div>
            </section>
        </React.Fragment>
        
        
    )
} 
export default AboutDetail;