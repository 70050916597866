import React , { useState ,useRef, useEffect } from 'react';
import Reveal from "react-reveal/Reveal/";
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import emailjs from "@emailjs/browser";

const HostingBanner =() =>{
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const phone_number = useRef(null);
    // const make = useRef(null);
    const sub_urb = useRef(null);
    const message = useRef(null);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    

    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_7k3zgnr",
                "template_37hnk2w",
                form.current,
                "bAqxsf-euN-TqlJ_L"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phone_number.current.value = "";
                    // make.current.value = "";
                    sub_urb.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    }

    useEffect(() => {
        if (status?.type === "success") {
            setShowSuccessMessage(true);
            setPopupOpen(false);

            const timeoutId = setTimeout(() => {
                setShowSuccessMessage(false);
            }, 2000);

            return () => clearTimeout(timeoutId);
        
        }
      }, [status]);

    return(
        <React.Fragment>
            {isPopupOpen ? (
                    <section className="h_security_banner_area ">
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className="security_banner_content">
                                        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Welcome To Renew Metal</h2>
                                        <p className="f_400 w_color l_height28 wow fadeInUp" data-wow-delay="0.4s">At Renew Metal, we are dedicated to recycling and renewing metals,<br/> specifically copper and brass. </p>
                                     </div>
                                </div>

                                <div className="contact_form col-sm-8 col-md-6 col-lg-4 mx-auto">
                                    <form
                                        ref={form}
                                        className="quote_form"
                                        action=""
                                        onSubmit={sendEmail}
                                    >
                                        
                                        <button className="close-button"  type='submit' onClick={() => setPopupOpen(false)}> X </button>
                                        
                                        {status?.type === "success" && (
                                            <p className="alert alert-success">
                                                Message Send Successfully
                                            </p>
                                            
                                        )}
                                        {status?.type === "error" && (
                                            <p className="alert alert-danger">
                                                Message Not Sent
                                            </p>
                                            
                                        )}
                                        <div className="row">
                                            <div className="form-group col-6 text_box">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Your Name"
                                                    required
                                                    ref={name}
                                                />
                                            </div>
                                            <div className="col-6 text_box">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Your Email"
                                                    required
                                                    ref={email}
                                                />
                                            </div>
                                            <div className="form-group col-6 text_box">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="phone-number"
                                                    name="phone_number"
                                                    placeholder="Your Phone Number"
                                                    required
                                                    ref={phone_number}
                                                />
                                            </div>
                                            <div className="form-group col-6 text_box">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="sub-urb"
                                                    name="sub_urb"
                                                    placeholder="Your Suburb"
                                                    required
                                                    ref={sub_urb}
                                                />
                                            </div>
                                            <div className="form-group col-md-12 text_box">
                                                <textarea
                                                    type="text"
                                                    class="form-control message"
                                                    id="message"
                                                    name="message"
                                                    placeholder="Your Message"
                                                    required
                                                    rows="1"
                                                    ref={message}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button
                                                className="btn_three"
                                                type="submit"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </section>
                    ) : (
                    <section className="h_security_banner_area">
                        <div className="container">
                            {showSuccessMessage && (
                                <p className="alert alert-success">
                                Message Sent Successfully
                                </p>
                            )}
                            <div className="security_banner_content">
                                <h2 className="wow fadeInUp" data-wow-delay="0.3s">Welcome To Renew Metal</h2>
                                <p className="f_400 w_color l_height28 wow fadeInUp" data-wow-delay="0.4s">At Renew Metal, we are dedicated to recycling and renewing metals,<br/> specifically copper and brass. </p>
                                <div className="action_btn align-items-center mt_40 wow fadeInUp" data-wow-delay="0.6s">
                                    <a href=".#" className="btn_hover app_btn" onClick={openPopup}>Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            
                
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <img src={require('../../img/RenewMetals/about.jpeg')} alt="" 
                                    height="339px"
                                    width="454px"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>About Us</h2>
                                    <p style={{ color: 'black' }}>At Renew Metal, we are dedicated to recycling and renewing metals, specifically copper and brass. With a commitment to sustainability and environmental responsibility, we aim to make a positive impact by reducing waste and conserving valuable resources. Our experienced team and state-of-the-art recycling processes ensure that every piece of copper and brass is given a new lease on life.</p>
                                    <div className="action_btn d-flex align-items-center mt_40 wow fadeInUp" data-wow-delay="0.6s">
                                        <Link to={`/About`} >
                                            <a href=".#" className="btn_hover app_btn">Read More</a>
                                        </Link>
                                    </div>
                                </div>

                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        
    )
}
export default HostingBanner;