import React, {Component,useState, useContext} from 'react';
import {Link, NavLink , useHistory, useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
// import {LocationContext} from '../Pages/store/LocationContext' ;

function CustomNavbar({ mClass, nClass, cClass, slogo, hbtnClass }) {

        // const { location, addLocation } = useContext(LocationContext);
        const [isMenuOpen, setMenuOpen] = useState(false);

        const toggleMenu = () => {
            setMenuOpen(!isMenuOpen);
        };

        const closeMenu = () => {
            setMenuOpen(false);
        };
        const loc = useLocation();
        const history = useHistory();

        const navigateHome = () => {
            history.push("/");
        };

        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                    <header className="header_area">
                        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                            <div className={`container ${cClass}`}>
                                <Link className={`navbar-brand ${slogo}`} to="/">
                                    <img
                                        src={require("../img/Mss/renew metal.png")}
                                        alt="logo"
                                        width="230px"
                                        height="160px"
                                    />
                                    <img
                                        src={require("../img/Mss/renew metal.png")}
                                        alt="logo"
                                        width="230px"
                                        height="160px"
                                        id="site-logo"
                                    />
                                    {/* <img src={require("../img/Mss/MSS Logo-100.jpg")} alt=""/> */}
                                    {/* <img src={require("../img/Mss/MSS Logo-100.jpg")} alt="logo"/> */}
                                </Link>
                                <button className={`navbar-toggler collapsed ${isMenuOpen ? 'active' : ''}`}
                                 type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen ? 'true' : 'false'} onClick={toggleMenu} aria-label="Toggle navigation" >
                                    <span className="menu_toggle">
                                        <span className="hamburger">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                        <span className="hamburger-cross">
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </span>
                                </button>

                                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link" role="button" onClick={() => { window.scrollTo(0, 0); closeMenu(); }}>
                                                Home
                                            </Link>
                                            
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/About" className="nav-link" role="button" onClick={() => { window.scrollTo(0, 0); closeMenu(); }}>
                                                About Us
                                            </Link>

                                            {/* <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "/About"
                                                        : "/About"
                                                }
                                                className="nav-link"
                                            >
                                                About Us
                                            </a> */}
                                        </li>
                                        <li className="nav-item" onClick={() => { navigateHome(); closeMenu(); }}>
                                            {/* <Link to="#process" className="nav-link">
                                                Process
                                            </Link> */}

                                            <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "#services"
                                                        : "/#services"
                                                }
                                                className="nav-link"
                                            >
                                                Services
                                            </a>
                                        </li>

                                        <li className="nav-item" >
                                            <Link to="/Contact" className="nav-link" role="button" onClick={() => { window.scrollTo(0, 0); closeMenu(); }}>
                                                Contact Us
                                            </Link>

                                            {/* <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "/Contact"
                                                        : "/contact"
                                                }
                                                className="nav-link"
                                            >
                                                Contact Us
                                            </a> */}
                                        </li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </nav>
                    </header>
            </Sticky>
        );
    
}

export default CustomNavbar;