import React, {Component} from 'react';
import Slider from 'react-slick'

class Pserviceslider extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '100px',
            responsive: [
                {
                  breakpoint: 1250,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '100px',
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                  }
                }
              ]
        };
        return(
            <Slider className="service_carousel" {...settings}>
                <div className="service_item">
                    {/* <div className="icon s_icon_one"><i className="ti-check"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Extensive Industry <br/> Experience</h4>
                    <p> With years of experience in the scrap metal industry, we have developed a deep understanding of the market dynamics and possess the expertise required to offer reliable services.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/undraw.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_two"><i className="ti-location-arrow"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Competitive Pricing</h4>
                    <p>We aim to provide the best value for our customers by offering competitive pricing based on current market rates. Our transparent pricing model ensures that you get a fair deal every time you choose us.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/inbox.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_three"><i className="ti-search"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Convenient and Efficient <br/> Services</h4>
                    <p>We understand that time is valuable to our customers. That's why we strive to make our services as convenient and efficient as possible. From easy scheduling to quick turnaround times, we ensure a hassle-free experience for all our clients.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/file.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_four"><i className="ti-stats-up"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Wide Range of Metal<br/>  Recycling Solutions</h4>
                    <p>Whether you are an individual homeowner or a large industrial entity, we have the capabilities to handle all your scrap metal recycling needs. From steel and aluminum to copper and brass, we accept a wide variety of metals.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/report.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_one"><i className="ti-check"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Environmentally Friendly <br/> Practices</h4>
                    <p>As an environmentally conscious business, we prioritize sustainable practices in all aspects of our operations. By recycling scrap metal, we contribute to the conservation of natural resources and the reduction of carbon emissions.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/undraw.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_two"><i className="ti-location-arrow"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">State-of-the-Art <br/> Equipment</h4>
                    <p>Our facility is equipped with the latest machinery and equipment, enabling us to process scrap metal efficiently and effectively. This ensures that you receive top-quality services and maximise the value of your scrap metal.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/inbox.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_three"><i className="ti-search"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Excellent Customer <br/> Service</h4>
                    <p>At our company, customer satisfaction is our top priority. Our team of friendly and knowledgeable professionals is always ready to assist you throughout the process, providing personalised support and addressing any questions or concerns you may have</p>
                    {/* <img className="float-right" src={require('../../../img/home2/file.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_four"><i className="ti-stats-up"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Licensed and Insured</h4>
                    <p>We are a licensed and insured scrap metal business, giving you peace of mind knowing that you are working with a reputable and trustworthy company.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/report.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_four"><i className="ti-stats-up"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Foster Long-Term <br/>Relationships</h4>
                    <p>We believe in building strong and long-lasting relationships with our clients. By consistently delivering exceptional service, we aim to become your trusted partner for all your future scrap metal recycling needs.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/report.png')} alt=""/> */}
                </div>
                <div className="service_item">
                    {/* <div className="icon s_icon_four"><i className="ti-stats-up"></i></div> */}
                    <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Community Engagement</h4>
                    <p>We actively engage with the local community, supporting initiatives and organisations that promote environmental conservation and social welfare. When you choose us, you are also contributing to these noble causes.</p>
                    {/* <img className="float-right" src={require('../../../img/home2/report.png')} alt=""/> */}
                </div>
                
            </Slider>
        )
    }
}
export default Pserviceslider;