
import React, {useState, useRef} from 'react';
import emailjs from "@emailjs/browser";

const Contacts = () => {
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const subject = useRef(null);
    const phone_number = useRef(null);
    const message = useRef(null);

    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_7k3zgnr",
                "template_37hnk2w",
                form.current,
                "bAqxsf-euN-TqlJ_L"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phone_number.current.value = "";
                    subject.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    }

        return(
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Office Address</h6>
                                <p className="f_400 f_size_15">Melbourne Australia</p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact Info</h6>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Phone:</span> <a href="tel:0387745990">03 8774 5990</a></p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Phone:</span> <a href="tel:0460786110">0460 786 110</a></p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span> <a href="mailto:info@renewmetals.com">info@renewmetal.com.au</a></p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-9">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
                            <form onSubmit={sendEmail} className="contact_form_box" ref={form} id="contactForm">
                                {status?.type === "success" && (
                                    <p className="alert alert-success">
                                                Message Send Successfully
                                    </p>
                                            
                                )}
                                {status?.type === "error" && (
                                    <p className="alert alert-danger">
                                        Message Not Sent
                                    </p>
                                            
                                )}
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="name" name="name" placeholder="Your Name" required ref={name}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" name="email" id="email" placeholder="Your Email" required ref={email}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="subject" name="subject" placeholder="Subject"  ref={subject}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text"
                                                    id="phone-number"
                                                    name="phone_number"
                                                    placeholder="Your Phone Number"
                                                    required
                                                    ref={phone_number}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea ref={message} name="message" id="message" cols="30" rows="10" required placeholder="Enter Your Message . . ."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">Send Message</button>
                            </form>
                            {/* {emailStatus ? emailStatus : null} */}
                            {/* <div id="success">Your message succesfully sent!</div>
                            <div id="error">Opps! There is something wrong. Please try again</div> */}
                        </div>
                    </div>
                    
                </div>
            </section>
        )
}

export default Contacts;