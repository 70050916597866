import React, {Component} from 'react';

class SecuirtyServiceItem extends Component {
    render(){
        let{image, rClass, Title, description}= this.props;
        return(
            <div className={`row h_analytices_features_item align-items-center ${rClass}`}>
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
                        <img className="wow fadeInUp" src={require("../../img/RenewMetals/why us.jpeg" )} alt=""
                            width="498px"
                            height="598px"
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                        <h2 className="wow fadeInUp" data-wow-delay="0.1s">{Title}</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">{description}</p>
                        <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s"><span>Commitment to Sustainability:</span> We are passionate about environmental stewardship and strive to promote sustainable practices in the metal recycling industry.</li>
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span>Cutting-Edge Technology: </span>  Our state-of-the-art recycling facilities and advanced processes ensure efficient and effective metal recycling with minimal environmental impact.</li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Competitive Pricing:</span> We offer fair and competitive prices for scrap copper and brass materials, providing an incentive for individuals and businesses to participate in the recycling process.</li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Excellent Customer Service:</span> -  Our dedicated team is committed to providing exceptional customer service, ensuring a seamless experience from collection to recycling.</li>
                        </ul>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default SecuirtyServiceItem;