import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/Conact-Banner.jpg" Ptitle="Contact Us" Pdescription="Ready to make a difference through metal recycling? Get in touch with us today to learn more about our services, schedule a pickup, or discuss any questions you may have. Let's work together to renew metals and create a sustainable future."/>
            <Contacts/>
            <FooterThree FooterData={FooterData} />
        </div>
    )
}
export default About;